import residentialVideo from "../../images/map/residential-house-video.mp4";
import townHouses from "../../images/map/town-house-video.mp4";
import slide1 from "../../images/map/Slide-2.webp";
import slide2 from "../../images/map/Slide-4.webp";
import slide5 from "../../images/map/ahed-outdoor-park.webp";
import slide6 from "../../images/map/ditached-ahed.webp";
import slide7 from "../../images/map/ditached-ahed-1.webp";
import slide8 from "../../images/map/park-commercial.webp";
import slide9 from "../../images/map/single-ahed.png";


const communityData = [
  {
    id: 1,
    link: "azha",
    title: "AZHA APARTMENTS – Modern Living Diverse Choices",
    title_ar: "أزها - الحياة العصرية، خيارات متنوعة",
    body: ` 
            <p>
              The Azha apartments are ideally located near the vibrant central plaza,
              providing residents with easy access to a wide range of social and
              commercial amenities.
            </p>
            <p>
              Positioned in the heart of the development, these homes offer the
              convenience of being at the center of Omani urban life, allowing
              residents to effortlessly engage with the surrounding cultural,
              retail, and dining experiences while still enjoying the comfort
              and privacy of their homes.
            </p>
            <p>
              This prime location ensures a dynamic lifestyle where everything
              is within reach.
            </p>
          `,
    body_ar: ` 
            <p>
              بالنسبة لأولئك الذين يبحثون عن تجربة حضرية، تقع أزها في واحة الصاروج بالقرب من الساحة المركزية النابضة بالحياة
            </p>
            <p>
              توفر هذه الموقع المثالي الوصول إلى مجموعة واسعة من المرافق الاجتماعية والثقافية والتجارية. سواء في تناول الطعام في مطعم محلي أو التسوق أو الاستمتاع بالفعاليات الثقافية، فإن السكان سيكونون دائمًا قريبين من الحدث
            </p>
            <p>
               توفر أزها أفضل ما في العالمين: الخصوصية والراحة، إلى جانب سهولة الوصول إلى قلب المشهد الحضري العماني. تضمن هذه الموقع المركزي أن يتمكن السكان من الانخراط في أسلوب حياة ديناميكي مع الحفاظ على ملاذ هادئ للعودة إليه
            </p>
          `,

    video: residentialVideo,
    images: [slide1, slide2, slide8],
    // iframe: "https://my.matterport.com/show/?m=QhyJe1Cnzez",
  },
  {
    id: 2,
    link: "ahed",
    title: "AHED VILLAS – Affordable Luxury, Connected Living ",
    title_ar: "عهد - الفخامة المعقولة، الحياة المتصلة ",
    body: ` <p>
              The Ahed in SAROOJ OASIS combines affordability with luxury, situated conveniently near the social center and boulevard.
            </p>
            <p>
              These 2 to 3-story villas are designed for sustainability and comfort, offering amenities and privacy like those in the villa zones.
            </p>
            <p>
              Their strategic location provides residents with easy access to vibrant community spaces and commercial hubs, seamlessly integrating modern living with ecofriendly design.
            </p>
          
          `,
    body_ar: ` <p>
          تجمع منطقة عهد في واحة الصاروج بين الفخامة المعقولة والاستدامة. تقع هذه الفيلات متعددة الطوابق (من 2 إلى 3 طوابق) بالقرب من المركز الاجتماعي والبازار، مما يوفر مزيجًا مثاليًا من الراحة والوظائف
            </p>
            <p>
              .تم تصميم عهد وفقًا للمبادئ البيئية، وهي مجهزة بأحدث المرافق مع التركيز على الكفاءة الطاقية
            </p>
            <p>
             .يوفر الموقع الاستراتيجي سهولة الوصول إلى المساحات المشتركة والحدائق والمراكز التجارية، مما يوفر نمط حياة متوازن مع شعور قوي بالانتماء إلى المجتمع
            </p>
          
          `,
    video: townHouses,
    images: [slide6, slide5, slide7, slide9],
    // iframe: "https://my.matterport.com/show/?m=dHmdWsQoY6o",
  },

  //   {
  //     id: 3,
  //     link: "Althuraya",
  //     title: "Althuraya – a peaceful valley retreat  ",
  //     title_ar: "التاون هاوس - الفخامة المعقولة، الحياة المتصلة ",
  //     body: ` <p>
  //               The villa zone is strategically situated along the valley line, offering residents unobstructed and picturesque views of the lush, serene valley.
  //             </p>
  //             <p>
  //              These homes are thoughtfully designed to be distinct from the nearby townhouse areas, ensuring privacy and exclusivity for the residents.
  //             </p>

  //           `,
  //     body_ar: ` <p>

  // في قلب واحة الصاروج، تقدم منطقة الفيلات تجربة سكنية حصرية، تقع بشكل استراتيجي على طول خط الوادي. تم تصميم الفيلات لتعظيم الاستفادة من المناظر الطبيعية الخلابة والوادي الهادئ الذي يميز هذه المنطقة من المشروع
  //             </p>
  //             <p>
  //              . ومع التركيز على الخصوصية، تظل منطقة الفيلات متميزة عن المناطق المجاورة من التاون هاوس، مما يضمن بيئة هادئة وساكنة لسكانها.
  //             </p>
  //             <p>
  //         . تعد هذه المنازل مثالية لأولئك الذين يبحثون عن الهدوء والفخامة والتوازن بين الطبيعة والتصميم العصري
  //             </p>

  //           `,
  //     video: townHouses,
  //     images: [slide3, slide4, slide5, slide6],
  //     iframe: "https://my.matterport.com/show/?m=dHmdWsQoY6o",
  //   },
];
export default communityData;
