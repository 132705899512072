import React from "react";
import video from "../../images/Sarooj_Haitam_City.mp4";
import { IoIosArrowDown } from "react-icons/io";

function Video({ scrollToCommunity }) {
  return (
    <div className="relative w-[100%] h-[100%] font-[cairo]">
      <video
        className="relative w-full h-[95vh] lg:h-[80vh] md:h-[60vh] lg:mt-[5%] object-cover z-0"
        autoPlay
        muted
        loop
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-[#00000057] z-10"></div>

      <div className=" w-[100%] text-[80px] absolute top-[85%] font-[100] text-white flex justify-center items-center z-20">
        <IoIosArrowDown
          onClick={scrollToCommunity}
          className="cursor-pointer bounce"
        />
      </div>
    </div>
  );
}

export default Video;
